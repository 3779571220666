<!-- 客户列表 -->
<template>
	<div class="bg-white px-3" style="margin: -20px;margin-top: -1rem; margin-bottom: 0!important;">
		<!-- 头部标签页 -->
		<el-tabs>
			<el-tab-pane label="全部"></el-tab-pane>
		</el-tabs>
		<!-- 客户列表 -->
		<el-table :data="tableData" style="width: 100%" border class="mt-3">
			<!-- 多选 -->
			<el-table-column type="selection" width="45" align="center">
			</el-table-column>

			<el-table-column align="center" label="客户姓名" width="100">
				<template slot-scope="scope">
					<span>{{scope.row.name}}</span>
				</template>
			</el-table-column>

			<el-table-column align="center" label="联系方式" width="160">
				<template slot-scope="scope">
					<span>{{scope.row.phone}}</span>
				</template>
			</el-table-column>

			<el-table-column width="200" align="center" label="微信号">
				<template slot-scope="scope">
					<span>{{scope.row.weixin}}</span>
				</template>
			</el-table-column>

			<!-- 配送方式 -->
			<el-table-column align="center" label="QQ号" width="160">
				<template slot-scope="scope">
					<span>{{scope.row.qq}}</span>
				</template>
			</el-table-column>

			<!-- 交易状态 -->
			<el-table-column align="center" width="450" label="客户需求">
				<template slot-scope="scope">
					<div style="font-size: 12px;color: #e70003;">留言时间：{{scope.row.create_time}}</div>
					<div>需求:
						<span>{{scope.row.title}}</span>
					</div>
				</template>
			</el-table-column>

			<!-- 操作 -->
			<el-table-column align="center" label="操作" width="220">
				<template slot-scope="scope">
					<el-button type="warning" size="mini" @click="upda(scope.row.id)" v-if="scope.row.dd == 0">待回复</el-button>
					<el-button type="success" size="mini" v-else>已回复</el-button>
					<el-button type="danger" size="mini" @click="deletes(scope.row.id)">删除</el-button>
				</template>
			</el-table-column>
		</el-table>

	</div>
</template>

<script>
	import common from "@/common/mixins/common.js" // 引入 全局公共样式
	export default {
		inject: ['layout'],
		mixins: [common], // 引入mixins里的common.js
		data() {
			return {
				tableData: [],
			}
		},
		// 生命周期
		created() {
			this.getlist()
		},
		// 生命周期监听页面
		methods: {
			getlist(){
				this.axios.get('/admin/weixin/1', {
					token: true
				}).then(res => {
					this.tableData = res.data.data.list
				})
			},
			deletes(e){
				this.axios.delete('/admin/weixin/'+e, {
					token: true
				}).then(res => {
					this.getlist()
				})
			},
			upda(e){
				console.log(e)
				this.axios.post('/admin/weixin/'+e, {
					token: true
				}).then(res => {
					this.getlist()
				})
			}
		}
	}
</script>

<style>
</style>
